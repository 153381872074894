import './Shop.css'
import Img1 from './files/1.png'



function Shop() {

	return (
		<main className='shop'>
			<h1>Shop</h1>
			<div>
				<img src={Img1} alt="" />
			</div>
		</main>
	)
}

export default Shop;